import React from 'react'

import SEO from '../../components/seo'

import avatar from '../../assets/images/avatar.png'
import styles from './styles.module.scss'
const IndexPage = () => {
  return (
    <>
      <SEO
        title="MDM house | Политика кониденциальности"
        description="MDM house - Политика конфиденциальности персональных данных."
        image={avatar}
        htmlAttributes={{
          lang: 'ru',
        }}
        url="https://mdm-remont.ru"
        keywords={[
          'политика кониденциальности',
        ]}
      />
      <div>
        <div className={styles.container}>
          <div>
            <h2>Коммерческий сайт</h2>
            <h2><strong>Политика конфиденциальности персональных данных</strong></h2>
            <p>Представленная Политика конфиденциальности персональных данных (далее Политика конфиденциальности)
              относится к любым данным, которые сайт mdm-remont.ru (далее Сайт) находящийся на доменном имени
              mdm-remont.ru
              (а также всех субдоменах), может получить о Пользователе во время работы с сайтом mdm-remont.ru (а также
              его
              субдоменами), его продуктами и программами.</p>
            <h2><strong>1. Определение терминов</strong></h2>
            <p>1.1. В
              настоящей Политике конфиденциальности используются следующие термины:
              <br /> <br />1.1.1. «Администрация сайта»
              (далее – Администрация) – сотрудники, наделенные Организацией полномочиями для управления сайтом
              mdm-remont.ru , которые от имени Организации занимаются обработкой и/или организацией персональных данных,
              определяют цели данной обработки, состав данных подлежащих ей, а также перечень операций, совершаемых с
              персональными данными.
              <br /> <br />1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или
              косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).
              <br /> <br />1.1.3.
              «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций),
              совершаемых с использованием средств автоматизации или без использования таких средств с
              персональными
              данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
              обезличивание, блокирование, удаление, уничтожение персональных данных.
              <br /> <br />1.1.4.
              «Конфиденциальность персональных данных» — обязательное соблюдение Администрацией сайта или иным
              лицом, получившим доступ к персональным данным, требования не допускать их распространения без
              наличия согласия субъекта персональных данных или иного законного основания.
              <br /> <br />1.1.5. «Сайт
              mdm-remont.ru » — это объединение всех связанных между собой веб-страниц, занимающих уникальный
              адрес (URL): mdm-remont.ru в сети Интернет, а также его субдомены.
              <br /> <br />1.1.6. «Субдомены» —
              это
              совокупность страниц, расположенная на доменах третьего уровня и находящаяся во владении сайта
              mdm-remont.ru , а также временные страницы, внизу которых указана контактная информация
              Администрации. <br /> <br />1.1.5. «Пользователь сайта mdm-remont.ru » (далее Пользователь) – любое
              лицо, имеющее
              доступ к сайту mdm-remont.ru и использующее размещённую информацию, материалы и
              продукты сайта mdm-remont.ru с помощью сети Интернет.
              <br /> <br />1.1.7. «Cookies» — это небольшая
              часть веб-данных, отправляемая сервером и сохраняющаяся на компьютере Пользователя,
              которую
              браузер при попытке открыть страницу соответствующего сайта пересылает обратно серверу в
              составе HTTP-запроса.
              <br /> <br />1.1.8. «IP-адрес» — уникальный сетевой адрес узла в
              компьютерной сети, через который Пользователь получает доступ на Сайт.
              <br /> <br />1.1.9.
              «Товар» — продукт или услуга, которые Пользователь заказывает на сайте и оплачивает
              через платёжные системы.
            </p>
            <h2><strong>2. Общие положения</strong></h2><p>2.1.
            Использование сайта mdm-remont.ru Пользователем является подтверждением согласия с данной Политикой
            Конфиденциальности и условиями обработки персональных данных Пользователя.<br /> <br />2.2. При несогласии с
            условиями Политики конфиденциальности или отдельными её пунктами Пользователь должен прекратить
            использование сайта mdm-remont.ru .<br /> <br />2.3. Данная Политика конфиденциальности распространяется
            только
            на сайт mdm-remont.ru . Сайт не несёт ответственность за ресурсы третьих лиц, на которые Пользователь
            может перейти по ссылкам, размещённым на сайте mdm-remont.ru . <br /> <br />2.4. Администрация я не
            проверяет
            подлинность персональных данных, получаемых от Пользователя.</p><h2><strong>3. Предмет политики
            конфиденциальности</strong></h2><p>3.1. Данная Политика конфиденциальности утверждает обязательства
            Администрации не разглашать и обеспечивать защиту конфиденциальности персональных данных, которые
            Пользователь предоставляет при регистрации на сайте mdm-remont.ru , при подписке на информационную e-mail
            рассылку или при оформлении заказа.<br /> <br />3.2. В рамках данной Политики конфиденциальности
            разрешёнными к
            обработке считаются персональные данные, предоставленные Пользователем путём заполнения форм на сайте
            mdm-remont.ru и включающие в себя следующие сведения:<br /> <br />3.2.1. фамилию, имя, отчество
            Пользователя;<br /> <br />3.2.2. контактный телефон Пользователя;<br /> <br />3.2.3. адрес электронной почты
            (e-mail);<br /> <br />3.2.4. место жительство Пользователя (при необходимости);<br /> <br />3.2.5. адрес
            доставки Товара (при необходимости);<br /> <br />3.2.6. фотографию (при необходимости).<br /> <br />3.3.
            Сайт защищает Данные, автоматически передающиеся при посещении страниц: — IP адрес; — информация
            из cookies; — информация о браузере — время доступа; — реферер (адрес предыдущей страницы).<br />
            <br />3.3.1. Отключение cookies может привести к недоступности или некорректной работе тех частей
            сайта, которые требуют авторизации.<br /> <br />3.3.2. Сайт проводит сбор статистики об IP-адресах
            своих посетителей с целью предотвращения, выявления и решения технических проблем.<br /> <br />3.4.
            Прочая персональная информация неуказанная выше (используемые операционные системы,
            браузеры, история посещения и т.п.) подлежит надёжному хранению и нераспространению, кроме
            случаев, предусмотренных в п.п. 5.2. и 5.3. данной Политики конфиденциальности.</p>
            <h2>
              <strong>4. Цели сбора персональной информации пользователя</strong></h2><p>4.1. Персональные данные
            Пользователя могут использоваться Администрацией с целью:<br /> <br />4.1.1. Распознавания Пользователя,
            зарегистрировавшегося на сайте mdm-remont.ru для его последующей авторизации, оформления заказа и прочих
            действий.<br /> <br />4.1.2. Предоставления Пользователю доступа к персонализированным данным сайта
            mdm-remont.ru . <br /> <br />4.1.3. Налаживания с Пользователем обратной связи, включая отправку запросов и
            уведомлений касательно использования сайта mdm-remont.ru , оказания услуг, обработки запросов и заявок
            от Пользователя.<br /> <br />4.1.4. Установления места нахождения Пользователя с целью обеспечения
            безопасности и предотвращения случаев мошенничества. <br /> <br />4.1.5. Проверки достоверности и полноты
            персональных данных, предоставленных Пользователем.<br /> <br />4.1.6. Создания учетной записи для
            использования частей сайта mdm-remont.ru , если Пользователь дал на это согласие.<br /> <br />4.1.7.
            Уведомления Пользователя по электронной почте.<br /> <br />4.1.8. Предоставления Пользователю
            своевременной технической поддержки при возникновении проблем с использованием сайта
            mdm-remont.ru . <br /> <br />4.1.9. Предоставления Пользователю с его согласия специальных
            предложений, новостной рассылки, информации о ценах на товары и/или услуги и иных сведений
            от имени сайта mdm-remont.ru .<br /> <br />4.1.10. Осуществления рекламной деятельности с
            согласия Пользователя.</p><h2><strong>5. Способы и сроки обработки персональной
            информации</strong></h2><p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения
            срока, законными способами, включая информационные системы персональных данных с применением средств
            автоматизации или без их использования.<br /> <br />5.2. Пользователь понимает и соглашается с тем, что
            Администрация имеет право сообщить персональные данные Пользователя третьим лицам, в частности службам
            доставки, организациям почтовой связи (в том числе электронной), операторам электросвязи, исключительно
            для выполнения заказа, оформленного на сайте mdm-remont.ru , включая доставку Товара, документации или
            e-mail уведомлений.<br /> <br />5.3. Персональные данные Пользователя могут быть переданы уполномоченным
            органам государственной власти Российской Федерации по основаниям и в порядке, установленным
            законодательством Российской Федерации. <br /> <br />5.4. В случае утраты или разглашения персональных
            данных
            Администрация оставляет за собой право не информировать об этом Пользователя.<br /> <br />5.5.
            Администрация выполняет все обязательные технические и организационные меры для защиты персональных
            сведений Пользователя от случайного или неправомерного доступа, изменения, копирования,
            распространения, блокирования, уничтожения и прочих неправомерных действий третьих лиц.<br /> <br />5.6.
            Администрация совместно с Пользователем принимает необходимые меры по предотвращению убытков и
            прочих негативных последствий в связи с разглашением или утратой персональных данных Пользователя.
          </p><h2><strong>6. Права и обязанности сторон</strong></h2>
            <p><strong>6.1. Пользователь вправе:</strong><br /><br />
              6.1.1. Принимать свободное решение о сообщении своих персональных данных, необходимых для работы на
              сайте mdm-remont.ru и давать согласие на обработку этих сведений.<br /> <br />6.1.2. Обновлять, дополнять
              и
              изменять ранее предоставленные персональные данные в случае изменения данной информации. <br /> <br />6.1.3.
              Пользователь вправе получать от Администрации информации, касающейся обработки его данных, если данное
              право не ограничено в соответствии с федеральным законодательством. Пользователь вправе принимать
              предусмотренные законом меры по защите своих прав. Пользователь вправе требовать от Администрации
              уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные
              являются устаревшими, неточными, незаконно полученными или не соответствуют заявленной цели обработки.
              Для этого необходимо уведомить Администрацию по указанному E-mail адресу.<br /> <br /><strong>6.2.
                Администрация обязана:</strong><br /> <br />6.2.1. Использовать полученные сведения исключительно в
              целях, указанных в п. 4 данной Политики конфиденциальности.<br /> <br />6.2.2. Гарантировать хранение
              конфиденциальной информации в тайне, не разглашать данные Пользователя без его предварительного
              письменного разрешения. Так же не осуществлять продажу, обмен, опубликование или разглашение
              любыми другими способами полученных персональных данных Пользователя за исключением п.п. 5.2. и
              5.3. данной Политики конфиденциальности. <br /> <br />6.2.3. Использовать весь комплекс мер по защите
              конфиденциальности персональных данных Пользователя исходя из порядка, обычно используемого для
              защиты подобной информации.<br /> <br />6.2.4. Осуществить блокирование персональных данных,
              относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или
              его законного представителя либо уполномоченного органа по защите прав субъектов персональных
              данных на период проверки, в случае выявления недостоверных персональных данных или
              неправомерных действий.</p><h2><strong>7. Ответственность сторон</strong></h2><p>7.1.
            Администрация, не исполнившая или нарушившая свои обязательства, несёт ответственность за убытки
            Пользователя в связи с неправомерным использованием его персональных данных, в соответствии с
            законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2.
            данной Политики конфиденциальности.<br /> <br />7.2. В случае утраты или разглашения Конфиденциальной
            информации
            Администрация не несёт ответственность, если данная конфиденциальная информация:<br /> <br />7.2.1. Стала
            публичным достоянием до её утраты или разглашения.<br /> <br />7.2.2. Была получена от третьей стороны до
            момента её получения Администрацией Ресурса.<br /> <br />7.2.3. Была разглашена с согласия
            Пользователя.<br /> <br />7.3. Пользователь несет полную ответственность за соблюдение требований
            законодательства РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране
            товарных знаков и знаков обслуживания и т.д., включая полную ответственность за содержание и форму
            материалов.<br /> <br />7.4. Пользователь признает, что ответственность за любую информацию (в том
            числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ
            как к части сайта mdm-remont.ru , несет лицо, предоставившее информацию.<br /> <br />7.5. Пользователь
            соглашается, что информация, предоставленная ему как часть сайта mdm-remont.ru , может являться
            объектом интеллектуальной собственности, права на который защищены и принадлежат другим
            Пользователям, партнерам или рекламодателям, которые разместили данную информацию на сайте
            mdm-remont.ru . Пользователь не вправе вносить изменения, передавать в аренду, передавать на
            условиях займа, продавать, распространять или создавать производные работы на основе такого
            Содержания (частично или полностью), за исключением случаев, когда такие действия были
            письменно разрешены собственниками такого Содержания в соответствии с условиями отдельного
            соглашения.<br /> <br />7.6. Допускается распространение текстовых материалов (статей, публикаций,
            находящихся в свободном публичном доступе на сайте mdm-remont.ru ) при условии, что будет дана
            ссылка на Сайт.<br /> <br />7.7. Администрация не несет ответственности перед Пользователем за
            любой убыток или ущерб, понесенный им в результате удаления, сбоя или невозможности
            сохранения какого-либо Содержания и иных коммуникационных данных, имеющихся на сайте
            mdm-remont.ru или передаваемых через него.<br /> <br />7.8. Администрация не несет
            ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования
            либо невозможности использования сайта или отдельных сервисов; несанкционированного
            доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на
            сайте.<br /> <br />7.9. Администрация не несет ответственность за какую-либо информацию,
            размещенную пользователем на сайте mdm-remont.ru , включая, но не ограничиваясь:
            информацию, защищенную авторским правом, без прямого согласия владельца авторского
            права.</p><h2><strong>8. Разрешение споров</strong></h2><p>8.1. До обращения в суд с
            иском по спорам, возникшим между Пользователем и Адмистрацией, обязательным является составление претензии
            (письменного или в электронном виде предложения о досудебном урегулировании спора).<br /> <br />8.2.
            Получатель
            претензии в течение 30 календарных дней с момента получения, письменно или в электронном виде уведомляет
            заявителя о результатах рассмотрения претензии.<br /> <br />8.3. При не достижении соглашения спор будет
            передан на рассмотрение Арбитражного суда г. Иркутск.<br /> <br />8.4. К данной Политике конфиденциальности
            и отношениям между Пользователем и Администрацией применяется действующее законодательство Российской
            Федерации.</p><h2><strong>9. Дополнительные условия</strong></h2><p>9.1. Администрация вправе вносить
            изменения в данную Политику конфиденциальности без согласия Пользователя.<br /> <br />9.2. Новая Политика
            конфиденциальности вступает в силу с момента ее размещения на сайте mdm-remont.ru , если иное не
            предусмотрено новой редакцией Политики конфиденциальности.<br /> <br />9.3. Все предложения или вопросы
            касательно настоящей Политики конфиденциальности следует сообщать по адресу: mdm.house61@gmail.com<br />
            <br />9.4.
            Действующая Политика конфиденциальности размещена на странице по адресу:
            https://mdm-remont.ru/politika-konfidenczialnosti/<br /> <br />г. Таганрог</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
